* {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Lato", sans-serif;
  height: 100vh;
}

#app {
  height: 100vh;
}
